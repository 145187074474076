.footer {
  padding: 32px 0 26px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px -4px 4px 0px #0000000d;
}

.footer__logo {
  width: 146px;
  margin: 0 auto;
}

.footer__thumb {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.footer__wrapper {
  display: flex;
  justify-content: center;
  gap: 27px;
}

.footer__info {
  max-width: 211px;
  width: 100%;
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.98px;
  color: #9d98a8;
}

.footer__text {
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.98px;
  text-align: center;
  color: #9d98a8;
}

.footer__year {
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.98px;
  text-align: center;
  color: #9d98a8;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.2);

  opacity: 1;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;

  max-width: 382px;
  width: 100%;
  height: 293px;
  padding: 12px;
  border-radius: 16px;

  border: 3px solid #eb9aa8;
  background: #ffffff;

  transform: scale(1) translate(-50%, -50%);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.footer__button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  max-width: 389px;
  margin: 16px auto 0;
  width: 100%;
  height: 58px;
  background: #ff0d09;
  border: transparent;
  border-radius: 12px;
  font-family: "Gilroy", sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 27.24px;
  color: #ffffff;
  text-transform: uppercase;
}

.modal__input {
  margin-top: 12px;
  max-width: 358px;
  width: 100%;
  border-radius: 12px;
  height: 62px;
  padding-left: 19px;
  background: #ecf5ff;
  font-family: "Gilroy", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 23.4px;
  text-align: left;
  color: #333333;

  border: 2px solid #ff0d09;
}

.modal__input::placeholder {
  font-family: "Gilroy", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 23.4px;
  text-align: left;
  color: #8f9499;
}

.modal__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.modal__button {
  margin-top: 12px;
  max-width: 358px;
  width: 100%;
  height: 62px;
  background: #ff0d09;

  border: 2px solid #f6aeae80;
  border-radius: 12px;
  font-family: "Gilroy", sans-serif;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 700;
  line-height: 26.07px;
  letter-spacing: -0.035em;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.modal__title {
  font-family: "Gilroy", sans-serif;
  font-size: 24px;
  font-weight: 800;
  line-height: 30px;
  text-align: center;
  color: #333333;
  text-transform: uppercase;
}

.modal__text {
  font-family: "Gilroy", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 23.4px;
  text-align: center;
  color: #333333;
  margin-top: 8px;
}

.modal3 {
  position: absolute;
  top: 50%;
  left: 50%;

  max-width: 390px;
  width: 100%;
  height: 212px;
  padding: 12px;
  border-radius: 16px;

  background: #fcfdff;
  transform: scale(1) translate(-50%, -50%);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.modal2 {
  position: absolute;
  bottom: 0%;
  left: 50%;

  max-width: 425px;
  width: 100%;
  height: 311px;
  padding: 16px;
  border-radius: 14px 14px 0 0;

  background: #ffffff;
  transform: scale(1) translate(-50%, 0);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.modal__top--wrapper {
  display: flex;
  justify-content: space-between;
}

.modal__top--wrapper2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.modal__top--text {
  font-family: "Gilroy", sans-serif;
  font-size: 24px;
  font-weight: 800;
  line-height: 30px;
  text-align: center;
  color: #333333;
  text-transform: uppercase;
}

.modal2__text {
  margin-top: 8px;
  font-family: "Gilroy", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.4px;
  text-align: left;
  color: #333333;
}

.modal2__button {
  max-width: 393px;
  width: 100%;
  height: 63px;
  font-family: "Gilroy", sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 37.92px;
  letter-spacing: -0.035em;
  text-align: center;
  color: #ffffff;
  background: #ff0d09;
  border-radius: 12px;
  cursor: pointer;
  border: transparent;
}

.modal2__input {
  margin-top: 16px;
  margin-bottom: 12px;
  background: #ecf5ff;
  border: 2px solid #ff0d09;
  max-width: 393px;
  width: 100%;
  height: 62px;
  padding-left: 19px;
  border-radius: 12px;
  font-family: "Gilroy", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 23.4px;
  text-align: left;

  color: #3a3a3a;
}

.modal2__input::placeholder {
  font-family: "Gilroy", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 23.4px;
  text-align: left;
  color: #8f9499;
}

@media screen and (max-width: 350px) {
  .footer__button {
    font-size: 20px;
  }

  .modal__text {
    font-size: 16px;
  }

  .modal__title {
    font-size: 22px;
  }

  .modal__top--text {
    font-size: 22px;
  }

  .modal2__text {
    font-size: 16px;
  }
}

@media screen and (min-width: 1000px) {
  .footer__info {
    max-width: 350px;
  }

  .footer {
    position: fixed;
    bottom: 0;
  }

  .footer__thumb {
    flex-direction: row;
    align-items: center;
  }
}
