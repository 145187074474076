.main {
  margin: 0 auto;
  padding: 115px 0 45px;
}

.main__title {
  font-family: "Rubik", sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 42.66px;
  letter-spacing: -0.035em;
  text-align: center;
  color: #333333;
  text-transform: uppercase;
}

.main__top--wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.main__item2--img2 {
  width: 150px;
}

.main__item2--thumb {
  display: flex;
  align-items: center;
  gap: 10px;
}

.main__top--trophy {
  width: 36px;
  height: 36px;
}

.main__item2__text {
  font-family: "Rubik", sans-serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 24.7px;
  letter-spacing: -0.035em;
  color: #ffffff;
}

.main__item2--btn {
  max-width: 362px;
  width: 100%;
  border-radius: 12px;
  font-family: "Gilroy", sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 39.62px;
  letter-spacing: -0.035em;
  color: #ffffff;
  background: #ff0d09;
  padding: 25px 0;
  border: transparent;
  cursor: pointer;
}

.main__thumb {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 22px;
  margin-top: 22px;
}

.main__item {
  position: relative;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  max-width: 386px;
  width: 100%;
  height: 316px;
  padding: 10px;
  border-radius: 15px;
  background: linear-gradient(180deg, #6d0605 0%, #1d0808 100%);
}

.main__item2 {
  position: relative;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  max-width: 386px;
  width: 100%;
  height: 316px;
  padding: 10px;
  border-radius: 15px;
  background: linear-gradient(180deg, #6d0605 0%, #1d0808 100%);
}

.main__item::after {
  content: "";
  position: absolute;
  top: 38%;
  right: -4.5%;
  background-image: url("../assets/coinAfter.png");
  width: 71px;
  height: 71px;
}

.main__item::before {
  content: "";
  position: absolute;
  top: 5%;
  left: -4.5%;
  background-image: url("../assets/coinBefore.png");
  width: 70px;
  height: 50px;
}

.main__wrap--top {
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  gap: 9px;
}

.main--button--top {
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  max-width: 366px;
  width: 100%;
  height: 90px;
  padding-left: 20px;
  border-radius: 12px;
  background: linear-gradient(102.05deg, #ff0d09 1.91%, #ff5c00 100%);

  border: transparent;
  overflow: hidden;
  cursor: pointer;
}

.main__item2--wrap {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.main__item2--img {
  width: 36px;
  height: 36px;
}

.main__item2--title {
  font-family: "Rubik", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 33.18px;
  letter-spacing: -0.035em;
  color: #ffffff;
  text-transform: uppercase;
}

@keyframes becomingBlue {
  0% {
    fill: #ff0d09;
    color: #ff0d09;
  }

  20% {
    fill: #ff0d09;
    color: #ff0d09;
  }

  80% {
    fill: #333;
    color: #333;
  }

  100% {
    fill: #ff0d09;
    color: #ff0d09;
  }
}

.main__text--btm {
  font-family: "Rubik";
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.035em;
  text-align: left;
  text-transform: uppercase;
  animation: becomingBlue 3s infinite;
}

.main__svg--arrow {
  animation: becomingBlue 3s infinite;
}

.main__btn--text {
  position: absolute;
  right: 4%;
  z-index: 1;
  color: #ffffff;
  text-transform: uppercase;

  font-family: "Rubik", sans-serif;
  font-size: 34.34px;
  font-weight: 700;
  line-height: 40.69px;
  letter-spacing: -0.035em;
  text-align: left;
}

.main__paralelogram {
  position: absolute;
  border-radius: 12px;
  top: 0;
  right: -3px;
  z-index: 0;
}

@keyframes movingTringle {
  0% {
    right: 24%;
  }
  80% {
    right: 17.6%;
  }

  100% {
    right: 24%;
  }
}

.main__svg--triangle {
  position: absolute;
  top: 0;
  right: 17.6%;
  z-index: 2;
  animation: movingTringle 3s infinite;
  height: 90px;
}

.main__text--top {
  font-family: "Gilroy", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 46px;
  text-align: center;
  color: #ffd600;
}

.main__text--bottom {
  margin-top: -14px;
  font-family: "Gilroy", sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 29.9px;
  text-align: center;
  color: #ffd600;
}

.main__prize--list {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 6px;
}

.main__prize--item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 160px;
  width: 100%;
  height: 57px;
  border-radius: 8px;
  background-color: #1a1f33cc;
}

.main__button {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 372px;
  width: 100%;
  height: 90px;
  border-radius: 12px;
  background: linear-gradient(180deg, #33d58c 0%, #1f9178 100%);
  border: transparent;
  font-family: "Gilroy", sans-serif;
  font-size: 28px;
  font-weight: 800;
  line-height: 32.68px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
}

.main__fade--text {
  font-family: "Gilroy", sans-serif;
  font-size: 21px;
  font-weight: 700;
  line-height: 23.1px;
  text-align: center;
  color: #ffffff;
}

.main__hot {
  width: 53px;
  height: 23px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.main__fade {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  max-width: 366px;
  width: 100%;
  height: 28px;
  background: radial-gradient(
    50% 15689.8% at 50% 50%,
    #d11111 0%,
    rgba(209, 17, 17, 0) 100%
  );
}

.main__star {
  width: 19px;
  height: 19px;
}

@media screen and (max-width: 390px) {
  .main--button--top {
    padding-left: 10px;
  }
}

@media screen and (max-width: 350px) {
  .main__prize--top {
    font-size: 21px;
  }

  .main__prize--bottom {
    font-size: 13px;
  }

  .main__title {
    font-size: 32px;
  }

  .main__item::after {
    content: "";
    position: absolute;
    top: 38%;
    right: -4.5%;
    background-image: url("../assets/coinAfter.png");
    width: 52px;
    height: 52px;
    background-size: cover;
  }

  .main--button--top {
    padding-left: 4px;
  }

  .main__item2--title {
    font-size: 24px;
  }

  .main__item2__text {
    font-size: 15px;
  }

  .main__text--btm {
    font-size: 17px;
  }
}

@media screen and (max-width: 330px) {
  .main__text--topSmaller {
    font-size: 39px;
  }
}

@media screen and (min-width: 1000px) {
  .main__thumb {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
  }
}
